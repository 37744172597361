<template>
  <div>
    <h3>My Notifications</h3>
  </div>
</template>

<script>
export default {
  
}
</script>